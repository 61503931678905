// General
import "./Nightsky.scss";
// Assets
import BlenderLogo from "../../assets/Logo/Blender Logo.png";
import UnityLogo from "../../assets/Logo/Unity Logo.png";
import GatekeeperConcept1 from "../../assets/Nightsky/Concept/Gatekeeper/Arena1_Plans.png";
import GatekeeperConcept2 from "../../assets/Nightsky/Concept/Gatekeeper/Arena1_Rendered.png";
import GatekeeperConcept3 from "../../assets/Nightsky/Concept/Gatekeeper/Arena1.png";
import GatekeeperConcept4 from "../../assets/Nightsky/Concept/Gatekeeper/Gate.png";
import GatekeeperConcept5 from "../../assets/Nightsky/Concept/Gatekeeper/walls.png";
import SewerConcept1 from "../../assets/Nightsky/Concept/Sewer/Arena2.png";
import SewerConcept2 from "../../assets/Nightsky/Concept/Sewer/Arena2_Plans.png";
import SewerConcept3 from "../../assets/Nightsky/Concept/Sewer/Arena2_CorridorPlans.png";
import SewerConcept4 from "../../assets/Nightsky/Concept/Sewer/Arena2_Iterations1.png";
import SewerConcept5 from "../../assets/Nightsky/Concept/Sewer/Arena2_Iterations2.png";
import SewerConcept6 from "../../assets/Nightsky/Concept/Sewer/Arena2_Iterations3.png";
import SewerConcept7 from "../../assets/Nightsky/Concept/Sewer/Arena2_Iterations4.png";
import Club1 from "../../assets/Nightsky/Concept/Club Nightsight/Arena3_Test.png";
import Club2 from "../../assets/Nightsky/Concept/Club Nightsight/Arena3_Plans.png";
import Club3 from "../../assets/Nightsky/Concept/Club Nightsight/Arena3_Iterations_3.png";
import Club4 from "../../assets/Nightsky/Concept/Club Nightsight/Arena3_Iterations 3.png";
import Club5 from "../../assets/Nightsky/Concept/Club Nightsight/Arena3_Iterations 4.png";
import Club6 from "../../assets/Nightsky/Concept/Club Nightsight/Arena3_Ideas.png";
import Deception1 from "../../assets/Nightsky/Concept/Hall of Deception/Arena5_Plan1.png";
import Deception2 from "../../assets/Nightsky/Concept/Hall of Deception/Arena5_Plan2.png";
import Deception3 from "../../assets/Nightsky/Concept/Hall of Deception/Arena5_Plan3.png";
import Incinerator1 from "../../assets/Nightsky/Concept/Incinerator/Arena 4_Plans.png";
import Incinerator2 from "../../assets/Nightsky/Concept/Incinerator/Arena 4_Mockup.png";
import Incinerator3 from "../../assets/Nightsky/Concept/Incinerator/Arena 4_Art.png";

import NightskyCover from "../../assets/Nightsky/Experimental - 1.jpg";
import Nightsky1 from "../../assets/Nightsky/Experimental - Gallery - 1.jpg";
import Nightsky2 from "../../assets/Nightsky/Experimental - Gallery - 2.jpg";
import Nightsky3 from "../../assets/Nightsky/Experimental - Gallery - 3.jpg";
import Nightsky4 from "../../assets/Nightsky/Experimental - Gallery - 4.jpg";
import Nightsky5 from "../../assets/Nightsky/Experimental - Gallery - 5.jpg";
import Nightsky6 from "../../assets/Nightsky/Experimental - Gallery - 6.jpg";
import Nightsky7 from "../../assets/Nightsky/Experimental - Gallery - 7.jpg";
import Nightsky8 from "../../assets/Nightsky/Experimental - Gallery - 8.jpg";
import Nightsky9 from "../../assets/Nightsky/Experimental - Gallery - 9.jpg";
import Nightsky10 from "../../assets/Nightsky/Experimental - Gallery - 10.jpg";
import Nightsky11 from "../../assets/Nightsky/Experimental - Gallery - 11.jpg";
import Nightsky12 from "../../assets/Nightsky/Experimental - Gallery - 12.jpg";

const Nightsky = () => {
  // Render
  return (
    <div className="nightsky-container">
      <div className="image-container">
        <img className="cover-img" src={NightskyCover} alt="1st section" />
      </div>

      <div className="description-container">
        <div className="description-label">Description</div>
        <div className="description">
          A game with high fidelity graphics intend to test out my skills in
          game design, 3D modeling and game development.
        </div>
      </div>

      <div className="info-container">
        <div className="tools-container">
          <div className="tools-label">Tools Used</div>
          <div className="tools-logo-container">
            <img src={BlenderLogo} alt="Blender Logo" />
            <img src={UnityLogo} alt="Unity Logo" />
          </div>
        </div>

        <div className="timeline-container">
          <div className="timeline-label">Expected Timeline</div>
          <div className="timeline">2 Years</div>
        </div>
      </div>

      <div className="additional-info-container">
        <div className="credits-container">
          <div className="credits-label">Credits</div>
          <div className="credits">Concept Artist: Mandy Chan (4threset)</div>
        </div>
      </div>

      <div className="progress-container">
        <div className="progress-label">Progress</div>

        <div className="image-group">
          {/* Concept Design */}
          <div className="sub-progress-label">Concept Design</div>
          <div className="img-row">
            <img className="sub-img" src={GatekeeperConcept1} alt="" />
            <img className="sub-img" src={GatekeeperConcept2} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={GatekeeperConcept3} alt="" />
            <img className="sub-img" src={GatekeeperConcept4} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={GatekeeperConcept5} alt="" />
            <img className="sub-img" src={SewerConcept1} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={SewerConcept2} alt="" />
            <img className="sub-img" src={SewerConcept3} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={SewerConcept4} alt="" />
            <img className="sub-img" src={SewerConcept5} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={SewerConcept6} alt="" />
            <img className="sub-img" src={SewerConcept7} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={Club1} alt="" />
            <img className="sub-img" src={Club2} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={Club3} alt="" />
            <img className="sub-img" src={Club4} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={Club5} alt="" />
            <img className="sub-img" src={Club6} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={Deception1} alt="" />
            <img className="sub-img" src={Deception2} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={Deception3} alt="" />
            <img className="sub-img" src={Incinerator1} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={Incinerator2} alt="" />
            <img className="sub-img" src={Incinerator3} alt="" />
          </div>

          {/* 3D Modeling */}
          <div className="sub-progress-label">3D Modeling</div>
          <div className="img-row">
            <img className="sub-img" src={Nightsky1} alt="" />
            <img className="sub-img" src={Nightsky2} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={Nightsky3} alt="" />
            <img className="sub-img" src={Nightsky4} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={Nightsky5} alt="" />
            <img className="sub-img" src={Nightsky6} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={Nightsky7} alt="" />
            <img className="sub-img" src={Nightsky8} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={Nightsky9} alt="" />
            <img className="sub-img" src={Nightsky10} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={Nightsky11} alt="" />
            <img className="sub-img" src={Nightsky12} alt="" />
          </div>

          {/* Game Development */}
          <div className="sub-progress-label">Game Development</div>
        </div>
      </div>
    </div>
  );
};

export default Nightsky;
