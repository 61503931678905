// General
import "./Home.scss";
// Components
import HomeIntroduction from "./Introduction/Introduction";
import Tldr from "./Tldr/Tldr";
import HomeDescription from "./Description/Description";
import HomeOnGoinProject from "./On-Going-Project/On-going-project";
import HomePortfolio from "./Portfolio/Portfolio";
import HomeTools from "./Tools/Tools";

const Home = () => {
  // Render
  return (
    <div className="home-container">
      {/* Introduction */}
      <HomeIntroduction />

      {/* Tldr */}
      <Tldr />

      {/* Description */}
      <HomeDescription />

      {/* Ongoing Projects */}
      <HomeOnGoinProject />

      {/* Portfolio */}
      <HomePortfolio />

      {/* Tools */}
      <HomeTools />
    </div>
  );
};

export default Home;
