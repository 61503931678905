// General
import "./Tldr.scss";
import moment from 'moment';

const Tldr = () => {
  const experience = moment().diff(moment('2020-05-01', 'YYYY-MM-DD'), 'years');

  // Render
  return (
    <div className="home-tldr-container">
      <div className="tldr-title">TLDR</div>

      <div className="tldr-body">
        <div className="border">
          <div className="title">Position</div>
          <div className="body">
            <div className="sub">Front End Web Developer</div>
            <div className="sub">Flutter Developer</div>
          </div>
        </div>
        <div className="border">
          <div className="title">Specialized Framework Used</div>
          <div className="body">
            <div className="sub">Angular</div>
            <div className="sub">Flutter</div>
          </div>
        </div>

        <div className="border">
          <div className="title">Years of Experience</div>
          <div className="body">{experience}+</div>
        </div>

        <div className="border">
          <div className="title">Projects Done</div>
          <div className="body">8</div>
        </div>
      </div>
    </div>
  );
};

export default Tldr;
