// General
import "./Tools.scss";
// Assets
import UnityLogo from "../../assets/Logo/Unity Logo.png";
import UnrealEngineLogo from "../../assets/Logo/Unreal-Engine-Logo.png";
import BlenderLogo from "../../assets/Logo/Blender Logo.png";
import HTML5Logo from "../../assets/Logo/HTML Logo.png";
import CSS3Logo from "../../assets/Logo/CSS Logo.png";
import JsLogo from "../../assets/Logo/Javascript Logo.png";
import TypeScriptLogo from "../../assets/Logo/Typescript Logo.png";
import AngularLogo from "../../assets/Logo/Angular Logo.png";
import ReactLogo from "../../assets/Logo/React-Logo.png";
import VueLogo from "../../assets/Logo/Vue-Logo.png";
import ElectronLogo from "../../assets/Logo/Electron-Temp.png";
import FlutterLogo from "../../assets/Logo/Flutter-Logo-Temp.png";

const HomeTools = () => {
  return (
    <div className="home-tools-container" id="tools">
      <div className="tools-label">Tools Used</div>

      <div className="design-container">
        <div className="design-label">Design</div>
        <div className="design-logo-container">
          <img className="logo-img" src={UnityLogo} alt="Unity" />
          <img className="logo-img" src={UnrealEngineLogo} alt="UnrealEngine5" />
          <img className="logo-img" src={BlenderLogo} alt="Blender" />
        </div>
      </div>

      <div className="development-container">
        <div className="development-label">Development</div>
        <div className="development-logo-container">
          <img className="logo-img html-logo" src={HTML5Logo} alt="HTML5" />
          <img className="logo-img css-logo" src={CSS3Logo} alt="CSS3" />
          <img className="logo-img js-logo" src={JsLogo} alt="Js" />
          <img className="logo-img ts-logo" src={TypeScriptLogo} alt="Ts" />
          <img
            className="logo-img angular-logo"
            src={AngularLogo}
            alt="Angular"
          />
          <img
            className="logo-img angular-logo"
            src={ReactLogo}
            alt="React"
          />
          <img className="logo-img angular-logo" src={VueLogo} alt="Vue" />
          <img
            className="logo-img electron-logo"
            src={ElectronLogo}
            alt="Electron"
          />
          <img
            className="logo-img flutter-logo"
            src={FlutterLogo}
            alt="Flutter"
          />
        </div>
      </div>
    </div>
  );
};

export default HomeTools;
