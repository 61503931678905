// General
import "./On-going-project.scss";
import { Link } from "react-router-dom";
// Material
import Button from "@mui/material/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// Assets
import Nightsky from "../../assets/Nightsky/Experimental - 1.jpg";

const HomeOnGoingProject = () => {
  const onGoingProject = [
    {
      title: "Project ARTiificial",
      tags: ["Machine Learning", "NFT", "React"],
      description:
        "A Machine Learning Platform that generates NFT for blockchain games",
      progress: "Designing Architecture",
      route: "/artiificial",
      image: "",
    },
    {
      title: "Project Fighters",
      tags: ["Unity", "C#", "Blender", "Blockchain"],
      description:
        "A side scroller and bullet hell game with blockchain integration for NFT as an asset.",
      progress: "3D Modeling",
      route: "/fighters",
      image: "",
    },
    {
      title: "Project Nightsky",
      tags: ["Unreal Engine 5", "C++", "Blender", "Substance Painter"],
      description:
        "A 3rd person Souls like genre games where player will need to constantly be aware of his surroundings to survive.",
      progress: "3D Modeling",
      route: "/nightsky",
      image: Nightsky,
    },
  ];

  return (
    <div className="home-ongoing-projects-container" id="ongoing-projects">
      <div className="ongoing-projects-label">Ongoing Projects</div>

      <div className="card-row">
        {/* Project ARTiificial */}
        <div className="artiificial blue-card">
          <div className="top-container">
            {/* <img className="card-image" src={Nightsky} alt="ARTificial" /> */}
            <div className="temporary-image"></div>
          </div>

          <div className="bottom-container">
            <div className="card-title">Project ARTiificial</div>
            <div className="progress-label">
              Current Progress: Designing Architecture
            </div>
            <div className="tag-container">
              <div className="blue-tag">Machine Learning</div>
              <div className="blue-tag">NFT</div>
              <div className="blue-tag">React</div>
            </div>
            <div className="card-description-container">
              <p className="card-description">
                A Machine Learning Platform that generates NFT for blockchain
                games
              </p>
              <p className="card-description">More information coming soon</p>
            </div>
            <div className="card-button-container">
              <div className="center">
                <Link className="case-study-link" to="/artiificial">
                  <Button className="case-study-button" variant="text">
                    View Progress
                  </Button>
                </Link>
                <ChevronRightIcon className="right-icon" />
              </div>
            </div>
          </div>
        </div>

        {/* Project Fighters */}
        <div className="fighters purple-card">
          <div className="top-container">
            {/* <img className="card-image" src={Nightsky} alt="SnipeAds" /> */}
            <div className="temporary-image"></div>
          </div>

          <div className="bottom-container">
            <div className="card-title">Project Fighters</div>
            <div className="progress-label">Current Progress: 3D Modeling</div>
            <div className="tag-container">
              <div className="purple-tag">Unity</div>
              <div className="purple-tag">C#</div>
              <div className="purple-tag">Blender</div>
              <div className="purple-tag">Blockchain</div>
            </div>
            <div className="card-description-container">
              <p className="card-description">
                A side scroller and bullet hell game with blockchain integration
                for NFT as an asset.
              </p>
            </div>
            <div className="card-button-container">
              <div className="center">
                <Link className="case-study-link" to="/fighters">
                  <Button className="case-study-button" variant="text">
                    View Progress
                  </Button>
                </Link>
                <ChevronRightIcon className="right-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-row">
        {/* Period Dart */}
        <div className="period blue-card">
          <div className="top-container">
            {/* <img className="card-image" src={Nightsky} alt="ARTificial" /> */}
            <div className="temporary-image"></div>
          </div>

          <div className="bottom-container">
            <div className="card-title">Period</div>
            <div className="progress-label">
              Current Progress: Adding parameters for formats
            </div>
            <div className="tag-container">
              <div className="blue-tag">Flutter</div>
              <div className="blue-tag">Dart</div>
              <div className="blue-tag">Package</div>
            </div>
            <div className="card-description-container">
              <p className="card-description">
                A momentjs inspired date package for Flutter/Dart.
              </p>
              <p className="card-description">
                When developing using Flutter framework, it does not have an
                extensive package similar to momentjs. The main goal of this
                package is to solve that, so that everyone can use date time
                functions easily.
              </p>
            </div>
            <div className="card-button-container">
              <div className="center">
                <Link className="case-study-link" to="/period">
                  <Button className="case-study-button" variant="text">
                    View Progress
                  </Button>
                </Link>
                <ChevronRightIcon className="right-icon" />
              </div>
            </div>
          </div>
        </div>

        {/* Project Nightsky */}
        <div className="nightsky purple-card">
          <div className="top-container">
            <img className="card-image" src={Nightsky} alt="SnipeAds" />
          </div>

          <div className="bottom-container">
            <div className="card-title">Project Nightsky</div>
            <div className="progress-label">Current Progress: 3D Modeling</div>
            <div className="tag-container">
              <div className="purple-tag">Unreal Engine 5</div>
              <div className="purple-tag">C++</div>
              <div className="purple-tag">Blender</div>
              <div className="purple-tag">Substance Painter</div>
            </div>
            <div className="card-description-container">
              <p className="card-description">
                A 3rd person Souls like genre games where player will need to
                constantly be aware of his surroundings to survive.
              </p>
            </div>
            <div className="card-button-container">
              <div className="center">
                <Link className="case-study-link" to="/nightsky">
                  <Button className="case-study-button" variant="text">
                    View Progress
                  </Button>
                </Link>
                <ChevronRightIcon className="right-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeOnGoingProject;
