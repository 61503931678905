// General
import "./Portfolio.scss";
import { Link } from "react-router-dom";
// Material
import Button from "@mui/material/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// Assets
import LM2500 from "../../assets/Home/Home - LM2500.png";
import Frame6B from "../../assets/Home/Home - Frame-6B-5.png";
import CCPW from "../../assets/Home/Home - CCPW-2.png";
import Enomad from "../../assets/Home/Home-Enomad.png";
import EnomadDriver from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-Home.png";
import SnipeAds from "../../assets/Home/Home-SnipeAds.png";
import SnipeAdsPlayer from "../../assets/SnipeAds-Player/snipe-player-default.png";

const HomePortfolio = () => {
  const portfolio = [
    {
      title: "SnipeAds",
      tags: ["Angular", "Front End"],
      description:
        "SnipeAds is an online self-serving platform where advertisers can log in and push their content to any screens with advanced targeting.",
      route: "/snipeads",
      image: SnipeAds,
    },
    {
      title: "Enomad",
      tags: ["Angular", "Front End"],
      description:
        "ENOMAD is an online self-serving platform where advertisers can log in and push their content to the screens on car top with advanced targeting.",
      route: "/enomad",
      image: Enomad,
    },
    {
      title: "SnipeAds Player",
      tags: ["Flutter", "Cross-Platform"],
      description:
        "SnipeAds Player is a companion app that can be installed on most devices that brings powerful feature for programmatic ads that can be controlled from the dashboard.",
      route: "/snipeads-player",
      image: SnipeAdsPlayer,
    },
    {
      title: "Enomad Driver App",
      tags: ["Flutter", "Mobile App"],
      description:
        "ENOMAD Driver app is a companion app for driver in the ENOMAD driver program that provide informations for drivers.",
      route: "/enomad-driver",
      image: EnomadDriver,
    },
    {
      title: "Combined Cycle Power Plant",
      tags: ["3D", "Blender"],
      description:
        "A 3D model of Combined Cycle Power Plant. The model is to represent a general view of the power plant with Gas Turbine and Steam Turbine in a complete package.",
      route: "/powerplant",
      image: CCPW,
    },
    {
      title: "Frame 6B GT",
      tags: ["3D", "Blender"],
      description:
        "A 3D model of the Frame 6B Gas Turbine. The model is used for sketch style presentation in client's website.",
      route: "/frame6b",
      image: Frame6B,
    },
    {
      title: "LM2500 GT",
      tags: ["3D", "Blender"],
      description:
        "A 3D model of the LM2500 Gas Turbine. The model have internal components that can be seen from the exploded view.",
      route: "/lm2500",
      image: LM2500,
    },
  ];

  return (
    <div className="home-portfolio-container" id="portfolio">
      <div className="portfolio-label">Portfolio</div>

      {portfolio.map((pf) => (
        <div className="card" key={pf.title}>
          <div className="left-container">
            <div className="card-title">{pf.title}</div>
            <div className="tag-container">
              {pf.tags.map((tag) => (
                <div className="tag" key={tag}>{tag}</div>
              ))}
            </div>
            <div className="card-description-container">
              <p className="card-description">{pf.description}</p>
            </div>
            <div className="card-button-container">
              <Link className="case-study-link" to={pf.route}>
                <Button className="case-study-button" variant="text">
                  View Case Study
                </Button>
              </Link>
              <ChevronRightIcon className="right-icon" />
            </div>
          </div>

          <div className="right-container">
            <img className="card-image" src={pf.image} alt={pf.title} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomePortfolio;
