// General
import "./Introduction.scss";
import moment from 'moment';

const HomeIntroduction = () => {
  const experience = moment().diff(moment('2020-05-01', 'YYYY-MM-DD'), 'years');

  // Render
  return (
    <div className="home-introduction-container">
      <div className="introduction">
        <div className="introduction-title">I'm Bryan Pang</div>
        <p className="introduction-description">
          <span className="stylized-blue">
            Front end web developer and 3D designer
          </span>{" "}
          based in Malaysia. Good with website development mainly Javascript and
          Angular. I have <span className="stylized-white">{experience} year+</span> of
          experience and have completed{" "}
          <span className="stylized-white">8 projects</span>.
        </p>
        <p className="introduction-description">
          I love to create things in general, coding is a form of creating
          something in software, and 3D modeling is creating something that
          mimics a real life object or something out of this world.
        </p>
      </div>
    </div>
  );
};

export default HomeIntroduction;
