// General
import "./LM2500.scss";
// Assets
import BlenderLogo from "../../assets/Logo/Blender Logo.png";
import lm2500Cover from "../../assets/LM2500/LM2500 - 1st Section.jpg";
import lm2500Parts1 from "../../assets/LM2500/LM2500 - Parts - 1.jpg";
import lm2500Parts2 from "../../assets/LM2500/LM2500 - Parts - 2.jpg";
import lm2500Parts3 from "../../assets/LM2500/LM2500 - Parts - 3.jpg";
import lm2500Parts4 from "../../assets/LM2500/LM2500 - Parts - 4.jpg";
import lm2500Parts1Sketch from "../../assets/LM2500/LM2500 - Parts - 1 - Sketch Style.jpg";
import lm2500Parts1SketchHidden from "../../assets/LM2500/LM2500 - Parts - 1 - Sketch Style hidden.jpg";
import lm2500Parts2Sketch from "../../assets/LM2500/LM2500 - Parts - 2 - Sketch Style.jpg";
import lm2500Parts2SketchHidden from "../../assets/LM2500/LM2500 - Parts - 2 - Sketch Style hidden.jpg";
import lm2500Parts3Sketch from "../../assets/LM2500/LM2500 - Parts - 3 - Sketch Style.jpg";
import lm2500Parts3SketchHidden from "../../assets/LM2500/LM2500 - Parts - 3 - Sketch Style hidden.jpg";
import lm2500Parts4Sketch from "../../assets/LM2500/LM2500 - Parts - 4 - Sketch Style.jpg";
import lm2500Parts4SketchHidden from "../../assets/LM2500/LM2500 - Parts - 4 - Sketch Style hidden.jpg";
import lm2500Carousel1 from "../../assets/LM2500/LM2500 - Carousel - 1.jpg";
import lm2500Carousel2 from "../../assets/LM2500/LM2500 - Carousel - 2.jpg";
import lm2500Carousel3 from "../../assets/LM2500/LM2500 - Carousel - 3.jpg";
import lm2500FinalOutput from "../../assets/LM2500/LM2500 - Final Output 1.jpg";

const LM2500 = () => {
  // Render
  return (
    <div className="lm2500-container">
      <div className="image-container">
        <img className="cover-img" src={lm2500Cover} alt="1st section" />
      </div>

      <div className="description-container">
        <div className="description-label">Description</div>
        <div className="description">
          A 3D model of the LM2500 Gas Turbine that provides power generation
          for multiple use case. I modeled and textured based on given
          schematics and blueprints that helps bring their website to life.
        </div>
      </div>

      <div className="info-container">
        <div className="tools-container">
          <div className="tools-label">Tools Used</div>
          <img src={BlenderLogo} alt="Blender Logo" />
        </div>

        <div className="timeline-container">
          <div className="timeline-label">Timeline</div>
          <div className="timeline">3 Months</div>
        </div>
      </div>

      <div className="gallery-container">
        <div className="gallery-label">Gallery</div>

        <div className="image-group">
          <div className="sub-gallery-label">Full Textured</div>
          <div className="img-row">
            <img className="sub-img" src={lm2500Parts1} alt="" />
            <img className="sub-img" src={lm2500Parts2} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={lm2500Parts3} alt="" />
            <img className="sub-img" src={lm2500Parts4} alt="" />
          </div>

          <div className="sub-gallery-label">Sketch Style</div>
          <div className="img-row">
            <img className="sub-img" src={lm2500Parts1Sketch} alt="" />
            <img className="sub-img" src={lm2500Parts2Sketch} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={lm2500Parts3Sketch} alt="" />
            <img className="sub-img" src={lm2500Parts4Sketch} alt="" />
          </div>

          <div className="sub-gallery-label">Sketch Style See Through</div>
          <div className="img-row">
            <img className="sub-img" src={lm2500Parts1SketchHidden} alt="" />
            <img className="sub-img" src={lm2500Parts2SketchHidden} alt="" />
          </div>
          <div className="img-row">
            <img className="sub-img" src={lm2500Parts3SketchHidden} alt="" />
            <img className="sub-img" src={lm2500Parts4SketchHidden} alt="" />
          </div>

          <div className="img-row">
            <img className="sub-img" src={lm2500Carousel2} alt="" />
            <img className="sub-img" src={lm2500Carousel3} alt="" />
          </div>

          <div className="img-row">
            <img className="single-img" src={lm2500Carousel1} alt="" />
          </div>

          <div className="sub-gallery-label">Final Output</div>
          <div className="img-row">
            <img className="single-img" src={lm2500FinalOutput} alt="" />
          </div>
        </div>
      </div>

      {/* In Progress */}
      {/* <div className="threejs">
                3d model
            </div> */}
    </div>
  );
};

export default LM2500;
