// General
import "./SnipeAds-player.scss";
// Assets
import FlutterLogo from "../../assets/Logo/Flutter-Logo-Temp.png";
import SnipeAdsPlayerImage from "../../assets/SnipeAds-Player/snipe-player-default.png";

const SnipeAdsPlayer = () => {
  // Render
  return (
    <div className="snipeads-player-container">
      <div className="image-container">
        <img
          className="cover-img"
          src={SnipeAdsPlayerImage}
          alt="1st section"
        />
      </div>

      <div className="description-container">
        <div className="description-label">Description</div>
        <div className="description">
          SnipeAds Player is a companion app that can be installed on most
          devices that brings powerful feature feature for programmatic ads that
          can be controlled from the dashboard. It communicates with our server
          to display medias that is managed on our SnipeAds dashbaord.
        </div>
      </div>

      <div className="info-container">
        <div className="horizontal-container">
          <div className="tools-container">
            <div className="tools-label">Tools Used</div>
            <div className="tools-logo-container">
              <img className="flutter-logo" src={FlutterLogo} alt="Flutter" />
            </div>
          </div>

          <div className="position-container">
            <div className="position-label">Position</div>
            <div className="position">Flutter Developer</div>
            <div className="position">Cross-Platform Developer</div>
          </div>
        </div>

        <div className="packages-container">
          <div className="packages-label">Packages Used</div>
          <div className="packages">socket_io_client</div>
          <div className="packages">dart_vlc</div>
          <div className="packages">hive</div>
          <div className="packages">hive_flutter</div>
          <div className="packages">http</div>
          <div className="packages">platform_device_id</div>
          <div className="packages">network_info_plus</div>
          <div className="packages">path</div>
          <div className="packages">path_provider</div>
          <div className="packages">internet_file</div>
          <div className="packages">logger</div>
        </div>
      </div>
    </div>
  );
};

export default SnipeAdsPlayer;
