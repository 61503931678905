// General
import "./Description.scss";
// Material
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";

const HomeDescription = () => {
  return (
    <div className="home-description-container">
      <div className="self-description">
        <h2 className="description-label">Who am I?</h2>
        <p className="description">
          Born in Malaysia, finish my{" "}
          <span className="stylized-white">
            BSc(Hons) Computer Network and Security
          </span>{" "}
          degree in Sunway University. Outside my full-time job, I do freelance
          projects to create stunning 3D models for clients.
        </p>
        <p className="description">
          I have a passion of creating stuff, and I have an interest in
          computing since I was young, so naturally I would pursue a degree in
          IT.
        </p>
        <p className="description">
          I admire art pieces, artwork, and digital illustration. But since
          drawing isn't my strong suit, I tried my hands on 3D modeling on my
          free time and I've been enjoying it ever since.
        </p>
        <p className="description">
          With the knowledge of coding and 3D modeling, my next goal would be to
          create a game in Unity or Unreal Engine with my own 3D assets.
        </p>
      </div>

      <div className="social-media-container">
        <h2 className="social-media-label">Social Medias</h2>
        <div className="social-media-logo-container">
          <a
            href="https://www.linkedin.com/in/bryan-p-827124168/"
            target="_blank"
          >
            <LinkedInIcon className="social-media-logo linkedin"></LinkedInIcon>
          </a>
          <a href="https://github.com/BPTH" target="_blank">
            <GitHubIcon className="social-media-logo github"></GitHubIcon>
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeDescription;
