// General
import "./Fighters.scss";
// Assets
import BlenderLogo from "../../assets/Logo/Blender Logo.png";
import UnityLogo from "../../assets/Logo/Unity Logo.png";
import HTML5Logo from "../../assets/Logo/HTML Logo.png";
import CSS3Logo from "../../assets/Logo/CSS Logo.png";
import JsLogo from "../../assets/Logo/Javascript Logo.png";

const Fighters = () => {
  // Render
  return (
    <div className="fighters-container">
      <div className="image-container">
        {/* <img className="cover-img" src={NightskyCover} alt="1st section" /> */}
      </div>

      <div className="description-container">
        <div className="description-label">Description</div>
        <div className="description">
          A side scroller and bullet hell game that is build on the blockchain
          to integrate NFT as assets
        </div>
      </div>

      <div className="info-container">
        <div className="tools-container">
          <div className="tools-label">Tools Used</div>
          <div className="tools-logo-container">
            <img src={BlenderLogo} alt="Blender Logo" />
            <img src={UnityLogo} alt="Blender Logo" />
            <img src={HTML5Logo} alt="Unity Logo" />
            <img src={CSS3Logo} alt="CSS Logo" />
            <img src={JsLogo} alt="Javascript Logo" />
          </div>
        </div>

        <div className="timeline-container">
          <div className="timeline-label">Expected Timeline</div>
          <div className="timeline">3 Months</div>
        </div>
      </div>

      <div className="additional-info-container">
        <div className="role-container">
          <div className="role-label">Role</div>
          <div className="role">Web Developer</div>
        </div>

        <div className="credits-container">
          <div className="credits-label">Credits</div>
          <div className="credits">Game Designer / Developer: LyFe</div>
        </div>
      </div>

      <div className="progress-container">
        <div className="progress-label">Progress</div>

        <div className="image-group">
          {/* Architecture */}
          <div className="sub-progress-label">Architecture</div>

          {/* Design */}
          <div className="sub-progress-label">Design</div>
        </div>
      </div>
    </div>
  );
};

export default Fighters;
