// General
import "./Enomad-driver.scss";
// Assets
import FlutterLogo from "../../assets/Logo/Flutter-Logo-Temp.png";
import EnomadDriverHome from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-Home.png";
import EnomadDriverComponentFlow from "../../assets/Enomad-Driver/Component-Flow.drawio.png";
import EnomadDriverDashboardFlow from "../../assets/Enomad-Driver/Dashboard-Flow.drawio.png";
import EnomadDriverLoginFlow from "../../assets/Enomad-Driver/Login-Data-Flow.drawio.png";
import EnomadDriver1 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-1.png";
import EnomadDriver2 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-2.png";
import EnomadDriver3 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-3.png";
import EnomadDriver4 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-4.png";
import EnomadDriver5 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-5.png";
import EnomadDriver6 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-6.png";
import EnomadDriver7 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-7.png";
import EnomadDriver8 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-8.png";
import EnomadDriver9 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-9.png";
import EnomadDriver10 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-10.png";
import EnomadDriver11 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-11.png";
import EnomadDriver12 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-12.png";
import EnomadDriver13 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-13.png";
import EnomadDriver14 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-14.png";
import EnomadDriver15 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-15.png";
import EnomadDriver16 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-16.png";
import EnomadDriver17 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-17.png";
import EnomadDriver18 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-18.png";
import EnomadDriver19 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-19.png";
import EnomadDriver20 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-20.png";
import EnomadDriver21 from "../../assets/Enomad-Driver/Enomad-Driver-Gallery-21.png";

const EnomadDriver = () => {
  // Render
  return (
    <div className="enomad-driver-container">
      <div className="image-container">
        <img className="cover-img" src={EnomadDriverHome} alt="1st section" />
      </div>

      <div className="description-container">
        <div className="description-label">Description</div>
        <div className="description">
          ENOMAD Driver app is a companion app for ENOMAD drivers. Drivers can
          check their driving hours in a weekly or monthly view to check if they
          have achieve their driving goal. Driver can also send monthly report
          of the LED topper condition through the app by taking pictures and
          videos. Every month, ENOMAD admin will bank in to drivers that
          achieved the driving goal, driver can view the receipt in the app to
          keep track of payments.
        </div>
      </div>

      <div className="info-container">
        <div className="horizontal-container">
          <div className="tools-container">
            <div className="tools-label">Tools Used</div>
            <div className="tools-logo-container">
              <img className="flutter-logo" src={FlutterLogo} alt="Flutter" />
            </div>
          </div>

          <div className="position-container">
            <div className="position-label">Position</div>
            <div className="position">Flutter Developer</div>
            <div className="position">Cross-Platform Developer</div>
          </div>
        </div>

        <div className="packages-container">
          <div className="packages-label">Packages Used</div>
          <div className="packages">sleek_circular_slider</div>
          <div className="packages">http</div>
          <div className="packages">intl</div>
          <div className="packages">flutter_spinkit</div>
          <div className="packages">url_launcher</div>
          <div className="packages">shared_preferences</div>
          <div className="packages">provider</div>
          <div className="packages">camera</div>
          <div className="packages">path_provider</div>
          <div className="packages">path</div>
          <div className="packages">video_player</div>
          <div className="packages">flutter_downloader</div>
          <div className="packages">permission_handler</div>
          <div className="packages">open_file</div>
        </div>
      </div>

      <div className="documentation-container">
        <div className="documentation-label">Documentation</div>

        <div className="image-group">
          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriverComponentFlow} alt="" />
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriverDashboardFlow} alt="" />
            </div>
          </div>

          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriverLoginFlow} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard-container">
        <div className="dashboard-label">Dashboard</div>

        <div className="image-group">
          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver1} alt="" />
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver2} alt="" />
            </div>
          </div>

          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver3} alt="" />
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver4} alt="" />
            </div>
          </div>

          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver5} alt="" />
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver6} alt="" />
            </div>
          </div>

          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver7} alt="" />
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver8} alt="" />
            </div>
          </div>

          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver9} alt="" />
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver10} alt="" />
            </div>
          </div>

          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver11} alt="" />
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver12} alt="" />
            </div>
          </div>

          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver13} alt="" />
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver14} alt="" />
            </div>
          </div>

          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver15} alt="" />
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver16} alt="" />
            </div>
          </div>

          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver17} alt="" />
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver18} alt="" />
            </div>
          </div>

          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver19} alt="" />
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver20} alt="" />
            </div>
          </div>

          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={EnomadDriver21} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnomadDriver;
