// General
import "./Navbar.scss";
import { HashLink } from "react-router-hash-link";
// Assets
import logo from "../assets/Logo/White Logo 1.png";

const Navbar = () => {
  // Render
  return (
    <div className="nav-container">
      <nav className="navbar">
        <HashLink smooth to="/#">
          <img src={logo} alt="logo" />
        </HashLink>
        <span className="spacer"></span>
        <div className="links">
          <HashLink className="nav-link" smooth to="/#ongoing-projects">
            Ongoing Projects
          </HashLink>
          <HashLink className="nav-link" smooth to="/#portfolio">
            Portfolio
          </HashLink>
          <HashLink className="nav-link" smooth to="/#tools">
            Tools
          </HashLink>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
