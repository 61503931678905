// General
import "./SnipeAds.scss";
// Assets
import HTML5Logo from "../../assets/Logo/HTML Logo.png";
import CSS3Logo from "../../assets/Logo/CSS Logo.png";
import JsLogo from "../../assets/Logo/Javascript Logo.png";
import AngularLogo from "../../assets/Logo/Angular Logo.png";
import HomeSnipeAds from "../../assets/Home/Home-SnipeAds.png";
import SnipeAds1 from "../../assets/SnipeAds/SnipeAds - Gallery - 1.jpg";
import SnipeAds2 from "../../assets/SnipeAds/SnipeAds - Gallery - 2.jpg";
import SnipeAds3 from "../../assets/SnipeAds/SnipeAds - Gallery - 3.jpg";
import SnipeAds4 from "../../assets/SnipeAds/SnipeAds - Gallery - 4.jpg";
import SnipeAds5 from "../../assets/SnipeAds/SnipeAds - Gallery - 5.jpg";
import SnipeAds6 from "../../assets/SnipeAds/SnipeAds - Gallery - 6.jpg";
import SnipeAds7 from "../../assets/SnipeAds/SnipeAds - Gallery - 7.jpg";
import SnipeAds8 from "../../assets/SnipeAds/SnipeAds - Gallery - 8.jpg";
import SnipeAds9 from "../../assets/SnipeAds/SnipeAds - Gallery - 9.jpg";
import SnipeAds10 from "../../assets/SnipeAds/SnipeAds - Gallery - 10.jpg";
import SnipeAds11 from "../../assets/SnipeAds/SnipeAds - Gallery - 11.jpg";
import SnipeAds12 from "../../assets/SnipeAds/SnipeAds - Gallery - 12.jpg";

const SnipeAds = () => {
  // Render
  return (
    <div className="snipeads-container">
      <div className="image-container">
        <img className="cover-img" src={HomeSnipeAds} alt="1st section" />
      </div>

      <div className="description-container">
        <div className="description-label">Description</div>
        <div className="description">
          ENOMAD is a proprietary digital signage software for Cartop Digital
          LED screens. The platform is an online self-serving platform where
          advertisers can log in and push their content to the screens according
          to location, weather, traffic and much more based on advanced
          targeting. The advertisers can set their budget to spend for
          advertising, and system will automatically convert it into ad plays
          and distribute to screens automatically.
        </div>
      </div>

      <div className="info-container">
        <div className="horizontal-container">
          <div className="tools-container">
            <div className="tools-label">Tools Used</div>
            <div className="tools-logo-container">
              <img src={HTML5Logo} alt="HTML Logo" />
              <img src={CSS3Logo} alt="CSS Logo" />
              <img src={JsLogo} alt="Javascript Logo" />
              <img src={AngularLogo} alt="Angular Logo" />
            </div>
          </div>

          <div className="position-container">
            <div className="position-label">Position</div>
            <div className="position">Lead Front End Developer</div>
            <div className="position">Front End Web Developer</div>
          </div>
        </div>

        <div className="packages-container">
          <div className="packages-label">Packages Used</div>
          <div className="packages">chart.js</div>
          <div className="packages">file saver</div>
          <div className="packages">html2canvas</div>
          <div className="packages">jspdf</div>
          <div className="packages">google maps</div>
          <div className="packages">geohash</div>
          <div className="packages">google recaptcha</div>
          <div className="packages">ng2 file upload</div>
          <div className="packages">cypress</div>
        </div>
      </div>

      <div className="dashboard-container">
        <div className="dashboard-label">Dashboard</div>

        <div className="image-group">
          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={SnipeAds1} alt="" />
              <div className="sub-description">
                Main dashboard page with useful informations and charts
              </div>
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={SnipeAds2} alt="" />
              <div className="sub-description">
                Main campaign page for quick view and filtering
              </div>
            </div>
          </div>

          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={SnipeAds3} alt="" />
              <div className="sub-description">
                Screen page where user can register players or create screens
              </div>
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={SnipeAds4} alt="" />
              <div className="sub-description">
                Screen Create page with multiple different fields and Google
                Maps with markers and circles
              </div>
            </div>
          </div>

          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={SnipeAds5} alt="" />
              <div className="sub-description">
                Inventory Management page where users can view details about
                which inventory has been filled with different campaigns
              </div>
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={SnipeAds6} alt="" />
              <div className="sub-description">
                Inveotory Management dialog where users can view more indepth
                details about which campaign is taking up the inventory
              </div>
            </div>
          </div>

          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={SnipeAds7} alt="" />
              <div className="sub-description">
                Group page for grouping multiple or favorite screen together for
                easier selection in the future
              </div>
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={SnipeAds8} alt="" />
              <div className="sub-description">
                User can mange their medias that have been uploaded; add tags or
                edit details
              </div>
            </div>
          </div>

          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={SnipeAds9} alt="" />
              <div className="sub-description">
                User can manage their payments in a simple and easy to
                understand page
              </div>
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={SnipeAds10} alt="" />
              <div className="sub-description">
                Campaign Create page with multiple different fields and Google
                Maps with markers and circles. User can view all stages of
                campaign with a stepper on top
              </div>
            </div>
          </div>

          <div className="img-row">
            <div className="img-description-container">
              <img className="sub-img" src={SnipeAds11} alt="" />
              <div className="sub-description">
                Second stage of Campaign Create allows user to upload media
              </div>
            </div>

            <div className="img-description-container">
              <img className="sub-img" src={SnipeAds12} alt="" />
              <div className="sub-description">
                Last stage of Campaign Create allows user to pay for their
                campaign for it to run
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnipeAds;
