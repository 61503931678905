// General
import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// Components
import ScrollToTop from "./Utility/ScrollToTop";
import Navbar from "./Navbar/Navbar";
import Home from "./Home/Home";
import Experimental from "./Experimental/Experimental";
import LM2500 from "./Portfolio/LM2500/LM2500";
import Frame6B from "./Portfolio/Frame6B/Frame6B";
import PowerPlant from "./Portfolio/PowerPlant/PowerPlant";
import Enomad from "./Portfolio/Enomad/Enomad";
import EnomadDriver from "./Portfolio/Enomad-Driver/Enomad-driver";
import SnipeAds from "./Portfolio/SnipeAds/SnipeAds";
import SnipeAdsPlayer from "./Portfolio/SnipeAds-Player/SnipeAds-player";
import Artiificial from "./Portfolio/Artiificial/Artiificial";
import Fighters from "./Portfolio/Fighters/Fighters";
import Period from "./Portfolio/Period/Period";
import Nightsky from "./Portfolio/Nightsky/Nightsky";

function App() {
  // Render
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navbar />
        <div>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/experimental" element={<Experimental />} />
            <Route path="/lm2500" element={<LM2500 />} />
            <Route path="/frame6b" element={<Frame6B />} />
            <Route path="/powerplant" element={<PowerPlant />} />
            <Route path="/enomad" element={<Enomad />} />
            <Route path="/enomad-driver" element={<EnomadDriver />} />
            <Route path="/snipeads" element={<SnipeAds />} />
            <Route path="/snipeads-player" element={<SnipeAdsPlayer />} />
            <Route path="/artiificial" element={<Artiificial />} />
            <Route path="/fighters" element={<Fighters />} />
            <Route path="/period" element={<Period />} />
            <Route path="/nightsky" element={<Nightsky />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
