// General
import "./Frame6B.scss";
// Assets
import BlenderLogo from "../../assets/Logo/Blender Logo.png";
import frame6bCover from "../../assets/Frame6B/Frame 6B - 1st Section.jpg";
import frame6bSketch from "../../assets/Frame6B/Frame 6B - 1st Section - Sketch Style.jpg";

const Frame6B = () => {
  // Render
  return (
    <div className="frame6b-container">
      <div className="image-container">
        <img className="cover-img" src={frame6bCover} alt="1st section" />
      </div>

      <div className="description-container">
        <div className="description-label">Description</div>
        <div className="description">
          A 3D model of Frame 6B Gas Turbine that provides power generation for
          multiple use case. I modeled and textured based on given schematics
          and blueprints that helps bring their website to life.
        </div>
      </div>

      <div className="info-container">
        <div className="tools-container">
          <div className="tools-label">Tools Used</div>
          <img src={BlenderLogo} alt="Blender Logo" />
        </div>

        <div className="timeline-container">
          <div className="timeline-label">Timeline</div>
          <div className="timeline">1 Months</div>
        </div>
      </div>

      <div className="gallery-container">
        <div className="gallery-label">Gallery</div>

        <div className="image-group">
          <div className="img-row">
            <img className="single-img" src={frame6bSketch} alt="" />
          </div>
        </div>
      </div>

      {/* In Progress */}
      {/* <div className="threejs">
                3d model
            </div> */}
    </div>
  );
};

export default Frame6B;
