// General
import "./PowerPlant.scss";
// Assets
import BlenderLogo from "../../assets/Logo/Blender Logo.png";
import CCPWCover from "../../assets/PowerPlant/CCPW - Section 1 - 3.jpg";
import CCPWShowCase1 from "../../assets/PowerPlant/CCPW - Show Case - 1.jpg";
import CCPWShowCase2 from "../../assets/PowerPlant/CCPW - Show Case - 2.jpg";
import CCPWShowCase3 from "../../assets/PowerPlant/CCPW - Show Case - 3.jpg";
import CCPWShowCase4 from "../../assets/PowerPlant/CCPW - Show Case - 4.jpg";
import CCPWShowCase5 from "../../assets/PowerPlant/CCPW - Show Case - 5.jpg";
import CCPWVideoBuildUp from "../../assets/PowerPlant/CCPW-Video-Build-Up.mp4";
import CCPWVideoRotate from "../../assets/PowerPlant/CCPW-Video-Rotate.mp4";

const PowerPlant = () => {
  // Render
  return (
    <div className="powerplant-container">
      <div className="image-container">
        <img className="cover-img" src={CCPWCover} alt="1st section" />
      </div>

      <div className="description-container">
        <div className="description-label">Description</div>
        <div className="description">
          A 3D model of a Combined Cycle Power Plant. I modeled and textured
          based on given schematics and blueprints that helps bring their
          website to life.
        </div>
      </div>

      <div className="info-container">
        <div className="tools-container">
          <div className="tools-label">Tools Used</div>
          <img src={BlenderLogo} alt="Blender Logo" />
        </div>

        <div className="timeline-container">
          <div className="timeline-label">Timeline</div>
          <div className="timeline">3 Months</div>
        </div>
      </div>

      <div className="gallery-container">
        <div className="gallery-label">Gallery</div>

        <div className="image-group">
          <div className="img-row">
            <img className="sub-img" src={CCPWShowCase1} alt="" />
            <img className="sub-img" src={CCPWShowCase2} alt="" />
          </div>

          <div className="img-row">
            <img className="sub-img" src={CCPWShowCase3} alt="" />
            <img className="sub-img" src={CCPWShowCase4} alt="" />
          </div>

          <div className="img-row">
            <video className="sub-video" controls>
              <source src={CCPWVideoBuildUp}></source>
            </video>
          </div>

          <div className="img-row">
            <video className="sub-video" controls>
              <source src={CCPWVideoRotate}></source>
            </video>
          </div>
        </div>
      </div>

      {/* In Progress */}
      {/* <div className="threejs">
                3d model
            </div> */}
    </div>
  );
};

export default PowerPlant;
